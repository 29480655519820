<template>
  <div class="softwareComponentSmallList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(softwareComponents)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(softwareComponents)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="typeLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }/software-components/${props.dataItem.id}`"
              class="alt-primary-color"
            >
              {{ props.dataItem.type.name }}
            </router-link>
          </td>
        </template>
        
        <template
          slot="urlTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <a 
              v-if="props.dataItem.url"
              :href="props.dataItem.url" 
              target="_blank"
              class="alt-primary-color"
            >
              {{ props.dataItem.url.length > 80 ? props.dataItem.url.substring(0, 80) + '...' : props.dataItem.url }}
            </a>
          </td>
        </template>
        <div
          slot="urlFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="urlFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a URL"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('urlFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="typeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Type"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('typeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </div>
</template>
<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "SoftwareComponentSmallList",
   mixins: [
    kendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      softwareComponents: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'key',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "key",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: 'Software Component Type',
          filterCell: 'typeFilter',
          cell: 'typeLink'
        },
        {
          field: 'url',
          filterable: true,
          filter: 'text',
          title: 'URL',
          filterCell: 'urlFilter',
          cell: 'urlTemplate'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('softwareComponent.softwareComponent')
    }
  },
  created () {
    this.getSoftwareComponents();
  },
  methods: {
    getSoftwareComponents () {
      this.loading = true;
      this.axios.get(`/SoftwareComponent/GetSoftwareComponents?installationId=${ this.installationId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.softwareComponents = response.data;
        let adaWeb = this.softwareComponents.find(x => x.type.name == 'James Ada Web');
        if(adaWeb && adaWeb.contentfulSpace && adaWeb.contentfulEnvironment) {
          this.$emit('contentfulBtn', {space: adaWeb.contentfulSpace, env: adaWeb.contentfulEnvironment})
        }
        if(this.softwareComponents && this.softwareComponents.length > 0) {
          this.softwareComponents = this.softwareComponents.sort((a, b) => a.type.name.localeCompare(b.type.name));
        }

      })
      .finally(() => {
        this.loading = false;
      });
    }     
  }
}
</script>